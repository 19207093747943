import React from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
import PdfViewer from "../../../../PdfViewer/PdfViewer";
import { NumericFormat } from "react-number-format";
export default function ClientCard(props) {
  const i = props.item;

  let brokerage = parseFloat(i.brokerage == null ? 0 : i.brokerage).toFixed(0);
  let pl = parseFloat(i.profit_loss == null ? 0 : i.profit_loss).toFixed(0);

  let futurepl = parseFloat(
    i.profit_loss_future == null ? 0 : i.profit_loss_future
  ).toFixed(0);
  let futurebrok = parseFloat(
    i.brokerage_future == null ? 0 : i.brokerage_future
  ).toFixed(0);
  let futurevol = parseFloat(
    i.amount_future == null ? 0 : i.amount_future
  ).toFixed(0);

  let mcxpl = parseFloat(
    i.profit_loss_mcx == null ? 0 : i.profit_loss_mcx
  ).toFixed(0);
  let mcxbrok = parseFloat(
    i.brokerage_mcx == null ? 0 : i.brokerage_mcx
  ).toFixed(0);
  let mcxvol = parseFloat(i.amount_mcx == null ? 0 : i.amount_mcx).toFixed(0);

  let optionspl = parseFloat(
    i.profit_loss_options == null ? 0 : i.profit_loss_options
  ).toFixed(0);
  let optionsbrok = parseFloat(
    i.brokerage_options == null ? 0 : i.brokerage_options
  ).toFixed(0);
  let optionsvol = parseFloat(
    i.amount_options == null ? 0 : i.amount_options
  ).toFixed(0);

  let start_date = i.start;
  let end_date = i.end;
  let deposit = parseFloat(i.deposit_total).toFixed(0);


  return (
    <div
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
          flexDirection: "column",
        }}
      >
        <div style={{fontSize:18}} >{i.client_username}</div>
        <div style={{paddingTop:2}} >({i.client_name})</div>
      </div>
      <div
        style={{
          color: i.profit_loss + i.brokerage > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 18,
        }}
      >
        <NumericFormat
          value={parseFloat(i.profit_loss + i.brokerage).toFixed(0)}
          displayType={"text"}
          thousandSeparator={true}
        />
        ₹
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 18,
        }}
      >
        <NumericFormat
          value={brokerage}
          displayType={"text"}
          thousandSeparator={true}
        />
        ₹
      </div>
      <div
        style={{
          color: i.profit_loss > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 18,
        }}
      >
        <NumericFormat
          value={pl}
          displayType={"text"}
          thousandSeparator={true}
        />
        ₹
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        <div
          style={{
            padding: 10,
            paddingLeft: 15,
            paddingRight: 15,
            backgroundColor: Dark.primary,
            borderRadius: 10,
          }}
        >
          <PdfViewer
            brokerage={brokerage}
            pl={pl}
            futurepl={futurepl}
            futurebrok={futurebrok}
            futurevol={futurevol}
            mcxpl={mcxpl}
            mcxbrok={mcxbrok}
            mcxvol={mcxvol}
            optionspl={optionspl}
            optionsbrok={optionsbrok}
            optionsvol={optionsvol}
            trade={i.order}
            username={i.client_username}
            name={i.client_name}
            start={start_date}
            end={end_date}
            deposit_total={deposit}
          />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        <div style={{ paddingRight: 5 }}>
          {moment(i.start).format("DD-MM-YYYY")}{" "}
        </div>
        <div>TO </div>
        <div style={{ paddingLeft: 5 }}>
          {" "}
          {moment(i.end).format("DD-MM-YYYY")}
        </div>
      </div>
    </div>
  );
}
