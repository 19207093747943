import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import TPassword from "../../../../Healer/TPassword/TPassword";
import { Dialog } from "primereact/dialog";
const backend = new Backend();

export default function PendingCard(props) {
  const i = props.item;
  const [show_cancel, setCancel] = useState(false);

  const [tpassword, setTPassword] = useState("");

  const Cancel = () => {
    setCancel(false);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: i.client_id,
      pending_id: i.id,
      tpassword: tpassword,
    };

    backend.cancel_pending(data).then((r) => {
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.load();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.client_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 14 }}>{i.symbol}</div>
        <div style={{ paddingTop: 5, fontSize: 10 }}>
          {moment(i.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          color: i.buy_sell == 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.buy_sell == 0 ? "BUY" : "SELL"}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.qty}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.price}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
        }}
      >
        {i.high_mid == 0 ? "HIGH-LOW" : "MID"}
      </div>
      <div
        style={{
          color: i.limit_sl == 0 ? Dark.primary : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
        }}
      >
        {i.limit_sl == 0 ? "LIMIT" : "STOP-LOSS"}
      </div>
      <div
        style={{
          color:
            i.status == 0 ? Dark.text : i.status == 1 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 11,
          textTransform: "uppercase",
        }}
      >
        {i.status == 0 ? "WAITING" : i.msg}
      </div>

      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 13 }}>
          {moment(i.cancel_date).format("DD-MM-YYYY")}
        </div>
        <div style={{ paddingTop: 3, fontSize: 13 }}>
          {moment(i.cancel_date).format("HH:mm:ss")}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 13 }}>
          {moment(i.date_created).format("DD-MM-YYYY")}
        </div>
        <div style={{ paddingTop: 3, fontSize: 13 }}>
          {moment(i.date_created).format("HH:mm:ss")}
        </div>
      </div>

    

      <div
        onClick={() => (i.status == 0 ? setCancel(true) : null)}
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.sell,
            color: Dark.text,
            borderRadius: 10,
            opacity: i.status == 0 ? 1 : 0.2,
          }}
        >
          CANCEL
        </div>
      </div>
      <Dialog
        visible={show_cancel}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setCancel(false)}
      >
        <TPassword
          Update={Cancel}
          tpassword={(a) => setTPassword(a)}
          text={"CANCEL"}
        />
      </Dialog>
    </div>
  );
}
